
import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomDropdown, { IDropdownItem } from '@/modules/common/components/ui-kit/custom-dropdown.vue';

@Component({
    components: {
        CustomDropdown,
    },
})
export default class ScheduledReportsItemMenu extends Vue {
    @Prop({
        required: true,
        type: String,
    })
    private reportId!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private isEditDisabled!: boolean;

    items(): IDropdownItem[] {
        return [
            {
                text: this.$tc('edit'),
                action: () => {
                    this.$emit('editReport', this.reportId);
                },
                disable: this.isEditDisabled,
            },
            {
                text: this.$tc('delete'),
                action: () => {
                    this.$emit('deleteReport', this.reportId);
                },
            },
        ];
    }
}
