
import ComplexSelect from '@/modules/common/components/ui-kit/complex-select.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import Item from '@/modules/common/interfaces/item.interface';
import { IFilterItem } from '@/modules/scheduled-reports/interfaces';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    components: {
        CustomMultiSelect,
        CustomSelect,
        ComplexSelect,
    },
})
export default class FiltersSection extends Vue {
    @Prop({
        default: [],
        type: Array,
    })
    values!: IFilterItem[];

    @Prop({
        default: false,
        type: Boolean,
    })
    isEdit!: boolean;

    handleChange(filter: IFilterItem, evValue: (string | number) | Item[] | (string | number)[]) {
        const value = evValue || filter.value;
        const propName = filter.name;
        const isComplexSelect = Array.isArray(filter.options[0]);

        if (isComplexSelect && Array.isArray(value)) {
            this.$emit('change', {
                propName,
                value: {
                    key: value[0],
                    value: value[1],
                },
            });
            return;
        }

        this.$emit('change', {
            propName,
            value,
        });
    }

    getFilterTitle(filter: IFilterItem) {
        const { options, value } = filter;

        if (Array.isArray(value)) {
            return (value as (string | number)[])
                .map((v: any) => (options as Item<any>[])
                    .find((o: Item) => o.value === v)?.name)
                .join(', ');
        }

        return (options as Item<any>[])
            .find((o: Item) => o.value === value)?.name;
    }
}
