
import { SCHEDULER_CONFIG } from '@/modules/scheduled-reports/constants';
import { $enum } from 'ts-enum-util';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomRadioGroup, { Item } from '@/modules/common/components/ui-kit/custom-radio-group.vue';

@Component({
    components: { CustomRadioGroup },
})
export default class FrequencyFilter extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    })
    disabled!: boolean;

    @Prop({
        type: String,
        required: true,
    })
    value!: SCHEDULER_CONFIG;

    get frequency() {
        return this.value;
    }

    set frequency(v: SCHEDULER_CONFIG) {
        this.$emit('input', v);
    }

    get frequencyItems(): Item[] {
        return $enum(SCHEDULER_CONFIG)
            .map((value): Item => ({
                value,
                name: this.$tc(`frequency.${value}`),
            }))
            .filter(item => !!item.value);
    }
}
