
import CustomSelectBordered, { Item } from '@/modules/common/components/ui-kit/custom-select-bordered.vue';

import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

@Component({
    components: {
        CustomSelectBordered,
    },
})
export default class RepeatEveryFilter extends Vue {
    @Prop({
        required: false,
        type: Boolean,
        default: false,
    })
    disabled!: boolean;

    @Prop({
        type: Number,
        required: true,
    })
    value!: number;

    get repeatEvery() {
        return this.value;
    }

    set repeatEvery(v: number) {
        this.$emit('input', v);
    }

    get repeatEveryItems(): Item[] {
        return [...Array(2).keys()].map((value): Item => ({
            value: value + 1,
            name: String(value + 1),
        }));
    }
}
