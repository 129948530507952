
import {
    Prop, Component, Vue,
} from 'vue-property-decorator';
import CustomRadio from '@/modules/common/components/ui-kit/custom-radio.vue';
import { RadioGroup } from 'element-ui';

export interface Item {
    value: number | string;
    name: string;
    disabled?: boolean;
}

@Component({
    components: {
        'el-radio-group': RadioGroup,
        CustomRadio,
    },
})
export default class CustomRadioGroup extends Vue {
    @Prop({
        required: true,
        type: String,
    })
    private value!: string;

    private currentValue: string = this.value;

    @Prop({
        required: true,
        type: Array as () => Item[],
        validator(value: any): boolean {
            return value.every((item: any) => ('value' in item && 'name' in item
                && typeof item.value === 'string'
                && typeof item.name === 'string'
                && (('disabled' in item && typeof item.disabled === 'boolean') || !('disabled' in item))));
        },
    })
    private items!: Item[];

    @Prop({
        required: false,
        type: String,
    })
    private color!: string;

    @Prop({
        required: false,
        type: String,
    })
    private label?: string;

    onChange(value: string) {
        this.$emit('input', value);
    }
}
