
import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomMultiSelect from './custom-multi-select.vue';

@Component({
    components: {
        CustomSelect,
        CustomMultiSelect,
    },
})
export default class ComplexSelect extends Vue {
    @Prop({
        type: Array,
        required: true,
    })
    value!: [(string | number), string | number | (string | number)[]];

    @Prop({
        type: Array,
        required: true,
    })
    items!: [Item[], Item[][]];

    @Prop({
        type: String,
        default: '',
    })
    label!: string;

    @Prop({
        type: Array,
        default: () => [false, false],
    })
    disabled!: [boolean, boolean];

    @Prop({
        type: Number,
    })
    maxSelected!: number;

    public get isItemsEmpty() {
        return this.value1Items.length === 0;
    }

    public get isMultiselect() {
        return this.value[1] instanceof Array;
    }

    public get value0() {
        return this.value[0];
    }

    public set value0(value: string | number) {
        const index = this.items[0].findIndex(item => item.value === value);
        const isItemsEmpty = this.items[1][index].length === 0;

        if (isItemsEmpty) {
            this.$emit('input', [value, this.isMultiselect ? [] : null]);
            return;
        }

        if (!this.isMultiselect) {
            this.value[1] = this.items[1][index][0].value;
            return;
        }

        const values = this.maxSelected !== undefined
            ? this.items[1][index].slice(0, this.maxSelected).map(item => item.value)
            : [this.items[1][index][0].value];

        this.$emit('input', [value, values]);
    }

    public get value1() {
        return this.value[1];
    }

    public set value1(value: string | number | (string | number)[]) {
        this.$emit('input', [this.value[0], value]);
    }

    public get value1Items() {
        const index = this.items[0].findIndex(item => item.value === this.value[0]);
        const items = this.items[1][index];

        const value1 = this.isMultiselect
            ? this.value[1] as (string | number)[]
            : this.value[1] as string | number;

        if (Array.isArray(value1)) {
            const haveItemNotInItems = value1.some(value => !items.some(item => item.value === value));

            if (haveItemNotInItems) {
                this.value1 = value1.filter(value => items.some(item => item.value === value));
            }
        } else if (!items.some(item => item.value === value1)) {
            this.value1 = items[0].value;
        }

        return items;
    }
}
