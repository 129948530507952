
import {
    Prop, Component, Vue,
} from 'vue-property-decorator';
import { Radio } from 'element-ui';

export interface Item {
    value: number | string;
    name: string;
    disabled?: boolean;
}

@Component({
    components: {
        'el-radio': Radio,
    },
})
export default class CustomRadio extends Vue {
    @Prop({
        required: true,
        type: String,
    })
    private value?: string;

    @Prop({
        required: true,
        type: Object,
    })
    private item!: Item;

    onChange() {
        this.$emit('input', this.item.value);
    }
}
