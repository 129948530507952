
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { DATA_TYPE, SCHEDULER_CONFIG, DAY_CONFIG } from '@/modules/scheduled-reports/constants';
import {
    IProperties, IFilterItem, ISchedulerConfig, IRecipient, ICustomColumn, IForm,
} from '@/modules/scheduled-reports/interfaces';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import loop24 from '@/modules/common/filters/loop-24.filter';
import MealTypeModel from '@/modules/meal-types/models/meal-type.model';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import RatesService, { RatesServiceS } from '../rates.service';
import RatesFiltersService, { RatesFiltersServiceS } from '../rates-filters.service';

export const COLUMNS_LIST = {
    market_demand: 'titles.marketDemand',
    occupancy: 'titles.occupancy',
    rank: 'titles.rank',
    diff_delta: 'titles.diffnum',
    diff_precent: 'titles.diffper',
    median: 'compset.median',
};

const COLUMNS_DISABLED_FOR_CHEAPEST = [
    'diff_delta',
    'diff_precent',
    'median',
];

const FILTER_SELECT_ALL = (provider: string[]) => provider.filter(p => p !== 'cheapest');

@Component({
    components: {
        ScheduledReportsPopup: () => import('@/modules/scheduled-reports'),
    },
})
export default class ScheduledReportsModalPage extends Vue {
    @inject(RatesServiceS) public ratesService!: RatesService;
    @inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(ProvidersServiceS) private providersService!: ProvidersService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @inject(UserSettingsS) private userSettingsService!: UserSettingsService;

    form: IForm = {} as IForm;
    private oldProviderList: string[] = [];

    get properties(): IProperties {
        return {
            dataType: DATA_TYPE.RATES,
            dateRange: {
                options: [30, 60, 90, 180, 365],
                value: 30,
            },
            fileType: {
                options: ['EXCEL'],
                value: 'EXCEL',
            },
        };
    }

    get customColumns(): ICustomColumn[] {
        const { customColumns: columns = {} } = this.form;
        const { providers = [] } = (this.form.filters || {}) as { providers: string[] };
        const isCheapestSelected = providers.includes('cheapest');

        return Object
            .entries(COLUMNS_LIST)
            .map(([name, label]) => {
                const disabled = isCheapestSelected && COLUMNS_DISABLED_FOR_CHEAPEST.includes(name);

                if (columns[name] === undefined) {
                    columns[name] = true;
                }

                const value = disabled ? false : (columns[name] as boolean);

                if (columns[name] !== value) {
                    columns[name] = value;
                }

                return {
                    name,
                    label: this.$tc(label),
                    value,
                    disabled,
                };
            });
    }

    get filters(): IFilterItem[] {
        const filters = [] as IFilterItem[];

        this.defineCompsetFilter(filters);
        this.definePosFilter(filters);
        this.defineProviderFilter(filters);
        this.defineLosFilter(filters);
        this.defineMealTypeFilter(filters);
        this.defineRoomTypeFilter(filters);
        this.defineOccupancyFilter(filters);
        this.definePriceTypeFilter(filters);
        this.definePriceFilter(filters);

        return filters;
    }

    private defineCompsetFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;

        const options = (this.compsetsService.compsets || [])
            .map(compset => ({
                name: compset.name,
                value: compset.id,
            }));

        const choosenCompset = (values?.compset
            || this.compsetsService.currentCompset?.id) as string;

        filters.push({
            name: 'compset',
            label: this.$tc('titles.compset'),
            value: choosenCompset,
            options,
            disableOnEdit: true,
        });
    }

    private definePosFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;

        const choosenPOS = (values.pos
            || this.documentFiltersService.settings.pos) as string;

        filters.push({
            name: 'pos',
            label: this.$tc('titles.pos'),
            value: choosenPOS,
            options: this.documentFiltersService.posRatesItems,
            disableOnEdit: false,
        });
    }

    private defineProviderFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;
        const { settings: globalSettings } = this.ratesFiltersService;

        const compset = this.compsetsService.getCompset(values.compset as string)
            || this.compsetsService.currentCompset;

        const providerList = compset?.rateProviders.filter(p => !['all'].includes(p)) || [];

        if (!Array.isArray(values.providers)) {
            values.providers = [globalSettings.provider || providerList[0] || 'booking'];
        }

        let choosenProvider = (
            values.providers
            || [globalSettings.provider || 'booking']
        ) as string[];

        choosenProvider = choosenProvider.filter(p => providerList.includes(p));

        const providerDifference = choosenProvider
            .filter(p => !this.oldProviderList.includes(p));

        const isCheapestSelected = providerDifference.includes('cheapest');

        choosenProvider = isCheapestSelected
            ? ['cheapest']
            : choosenProvider.filter(p => p !== 'cheapest');

        values.providers = choosenProvider;

        this.oldProviderList = choosenProvider;

        filters.push({
            name: 'providers',
            label: this.$tc('titles.provider'),
            value: choosenProvider,
            options: providerList
                .map(p => ({
                    name: this.providersService.getProviderLabel(p),
                    value: p,
                })),
            disableOnEdit: false,
            filterSelectAll: FILTER_SELECT_ALL,
        });
    }

    private defineLosFilter(filters: IFilterItem[]) {
        const { los } = this.documentFiltersService.settings;
        const { filters: values = {} } = this.form;

        const compset = this.compsetsService.getCompset(values.compset as string)
            || this.compsetsService.currentCompset;

        const choosenProviders = (values.providers || []) as string[];
        const haveDisabledProviders = choosenProviders
            .some(p => this.providersService.isDisabledProvider(p));

        const options = haveDisabledProviders
            ? compset!.los
            : DEFAULT_LOS;

        const defaultLos = options.includes(los!) ? los! : options[0];
        let choosenLos = (values.los || defaultLos) as number;

        if (!options.includes(choosenLos)) {
            choosenLos = defaultLos;
            values.los = defaultLos;
        }

        filters.push({
            name: 'los',
            label: this.$tc('titles.los'),
            value: choosenLos,
            options: options.map(l => ({
                name: this.$t('filters.los.num', [l]).toString(),
                value: l,
            })),
            disableOnEdit: false,
        });
    }

    private defineMealTypeFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;

        const { mealTypeId } = this.ratesFiltersService.settings;
        const { mealTypes } = this.mealTypesService;

        const defaultMealTypeValues = mealTypeId === ANY_MEAL_TYPE.id
            ? mealTypes
                .filter(mt => mt.id !== ANY_MEAL_TYPE.id)
                .map(mt => mt.name)
            : [this.mealTypesService.getMealType(mealTypeId)!.name];

        const choosenMealTypes = (values.meal_type || defaultMealTypeValues) as string[];

        filters.push({
            name: 'meal_type',
            label: this.$tc('titles.mealType'),
            value: choosenMealTypes,
            options: mealTypes
                .filter(mt => mt.id !== ANY_MEAL_TYPE.id)
                .map((mealType: MealTypeModel) => ({
                    value: mealType.name,
                    name: this.$tc(mealType.displayName),
                })),
            disableOnEdit: false,
        });
    }

    private defineRoomTypeFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;

        const { rooms } = this.roomTypesService;

        const { roomTypeId } = this.ratesFiltersService.settings;
        const defaultRoomTypeValues = roomTypeId === ANY_ROOM_TYPE.id
            ? rooms
                .filter(rt => rt.id !== ANY_ROOM_TYPE.id)
                .map(rt => rt.name)
            : [this.roomTypesService.getRoomType(roomTypeId)!.name];

        const choosenRoomTypes = (values.room_type || defaultRoomTypeValues) as number[];

        filters.push({
            name: 'room_type',
            label: this.$tc('titles.roomType'),
            value: choosenRoomTypes,
            options: rooms
                .filter(rt => rt.id !== ANY_ROOM_TYPE.id)
                .map((room: RoomTypeModel) => ({
                    value: room.name,
                    name: this.$tc(room.name),
                })),
            disableOnEdit: false,
        });
    }

    private defineOccupancyFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;

        const choosenOccupancy = values.occupancy
            || this.ratesFiltersService.settings.numberOfGuests
            || this.userSettingsService.defaultFilters.numberOfGuests;

        filters.push({
            name: 'occupancy',
            label: this.$tc('titles.numberOfGuests'),
            value: choosenOccupancy as number,
            options: Array
                .from({ length: 10 })
                .map((_, i) => i + 1)
                .map(e => ({
                    value: e,
                    name: this.$tc('filters.guests', e, [e]).toString(),
                })),
            disableOnEdit: false,
        });
    }

    private definePriceFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;

        const choosenPriceType = values.price
            || this.ratesFiltersService.settings.priceType
            || this.userSettingsService.defaultFilters.price;

        filters.push({
            name: 'price',
            label: this.$tc('titles.price'),
            value: choosenPriceType as PRICE_TYPE,
            options: [
                {
                    name: this.$tc(`price.${PRICE_TYPE.LOWEST}`),
                    value: PRICE_TYPE.LOWEST,
                },
                {
                    name: this.$tc(`price.${PRICE_TYPE.LOWEST_FLEX}`),
                    value: PRICE_TYPE.LOWEST_FLEX,
                },
                {
                    name: this.$tc(`price.${PRICE_TYPE.BEST_FLEX}`),
                    value: PRICE_TYPE.BEST_FLEX,
                },
                {
                    name: this.$tc(`price.${PRICE_TYPE.NON_REFUNDABLE}`),
                    value: PRICE_TYPE.NON_REFUNDABLE,
                },
            ],
            disableOnEdit: false,
        });
    }

    private definePriceTypeFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;

        let priceTypeValue = values.price_type as string;

        if (!priceTypeValue) {
            switch (this.documentFiltersService.priceShown) {
                case PRICE_SHOWN.TOTAL:
                    priceTypeValue = 'total_price';
                    break;
                case PRICE_SHOWN.NET:
                    priceTypeValue = 'net_price';
                    break;
                default:
                    priceTypeValue = 'shown_price';
                    break;
            }
        }

        filters.push({
            name: 'price_type',
            label: this.$tc('titles.priceShown'),
            value: priceTypeValue,
            options: [
                { value: 'shown_price', name: this.$tc('filters.price.shown') },
                { value: 'net_price', name: this.$tc('filters.price.net') },
                { value: 'total_price', name: this.$tc('filters.price.total') },
            ],
            disableOnEdit: false,
        });
    }

    get defaultFrequency() {
        return {
            type: SCHEDULER_CONFIG.DAILY,
            hour: loop24(new Date().getTimezoneOffset() / 60),
            minute: 0,
            month: 1,
            dayOfWeek: '6',
            dayOfMonth: 1,
            monthPeriod: DAY_CONFIG.FIRST,
            repeatEvery: 1,
        };
    }

    get frequency(): ISchedulerConfig {
        return this.defaultFrequency;
    }

    get recipients(): IRecipient[] {
        return [];
    }
}
