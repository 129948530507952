
import { Component, Vue, Prop } from 'vue-property-decorator';
import FileTypeFilter from './file-type-filter.vue';
import DateRangeFilter from './date-range-filter.vue';
import type { IProperties } from '../../../interfaces';

@Component({
    components: {
        FileTypeFilter,
        DateRangeFilter,
    },
})
export default class PropertiesSection extends Vue {
    @Prop({
        type: Object,
        required: true,
    })
    values!: IProperties;

    handleChange(propName: string, value: string | number) {
        this.$emit('change', {
            propName,
            value,
        });
    }
}
